import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Arrow from "../assets/image/arw.png";
import { Container, Col, Row, Form, Button, Table } from "react-bootstrap";
import swal from "sweetalert";
import { fetchKitDetails } from "../Redux/Actions";
import moment from "moment";
export const Profile = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [kitDetails, setKitDetails] = useState();
  useEffect(async () => {
    try {
      let body ={ kid: props.location.state.kitId, userId: props.location.state.userId}
      const response = await dispatch(
         fetchKitDetails(body)
        
      );
    
      const successResult = response?.payload;
      if (successResult) {
        if (successResult?.status == true) {
          setKitDetails(successResult?.data);
        } else {
          swal({
            title: "Error!",
            text: successResult?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Error",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  }, []);
  return (
    <>
      <div>
        <a href={`/dashboard`} class="go-back">
          <img src={Arrow} /> Go Back to Dashboard
        </a>
        <Container fluid className="for_wid">
          <Row className="kit_content_back">
            <Col lg={12} xl={6}>
              <div className="search-bar">
                <div class="kit_id">
                  {/* <h2>User Profile For Kit #{props.match.params.id}</h2> */}
                  <h2>User Profile For Kit #{kitDetails && kitDetails.kitNumber}</h2>
                  <p>
                    <strong>Username:</strong>{" "}
                    {kitDetails && kitDetails?.username}
                  </p>
                  <p>
                    <strong>Registration Date: </strong>
                    {moment(
                      new Date(
                        kitDetails && kitDetails?.Registration_date
                      ).toUTCString()
                    ).format("L")}
                  </p>
                  <p>
                    <strong>Deactivation Date:</strong>{" "}
                    {kitDetails && kitDetails?.deactivation_date != null
                      ? kitDetails && kitDetails?.deactivation_date != "--"
                        ? moment(
                            new Date(
                              kitDetails && kitDetails?.deactivation_date
                            ).toUTCString()
                          ).format("L")
                        : "--"
                      : "--"}
                  </p>
                  <p>
                    <strong>Date of Birth: </strong>

                    {kitDetails && kitDetails.dob !== null
                      ? kitDetails && kitDetails.dob != ""
                        ? moment(
                            new Date(
                              kitDetails &&
                                kitDetails.dob.split("/").reverse().join("/")
                            ).toUTCString()
                          ).format("L")
                        : "--"
                      : "--"}
                  </p>
                  <p>
                    <strong>City/State of Residence: </strong>{" "}
                    {kitDetails && kitDetails?.address}
                  </p>
                  <p>
                    <strong>Zip Code:</strong>{" "}
                    {kitDetails && kitDetails?.zipcode}
                  </p>
                  <p>
                    <strong>Gender:</strong> {kitDetails && kitDetails?.gender}
                  </p>
                  <p>
                    <strong>Ethnicity:</strong>{" "}
                    {kitDetails && kitDetails?.ethnicity}
                  </p>
                  <p>
                    <strong>Height(inches):</strong>{" "}
                    {kitDetails && kitDetails?.height}
                  </p>
                  <p>
                    <strong>Weight(lbs) : </strong>{" "}
                    {kitDetails && kitDetails?.weight}
                  </p>
                  {/* <a href="#">Send Reset Password Link</a> */}
                </div>
              </div>
            </Col>
            <Col lg={12} xl={6} style={{"border-left":"1px solid grey"}}>
              <div className="pro-table" >
                <p className="text-center"> <b>Devices</b></p>
                <p><b>PAF ID :</b> {kitDetails?.pafId}</p>
                <p><b>PURPLE AIR ID :</b>{kitDetails?.purpleAirId}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
