import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import swal from "sweetalert";
import { loginAction } from "../Redux/Actions";
import { setItemLocalStorage } from "../Utils/Util";
import { Container, Col, Row, Form, Button } from "react-bootstrap";
import Logo from "../assets/image/logo.png";
import Copy from "../assets/image/copyright.png";

export const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    email: "",
    password: "",
    adminId: "",
  });

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setValues({
      ...values,
      [name]: value,
    });
  };

  // ======= submit Login request ==============
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const response = await dispatch(loginAction(values));
      const loginData = response?.payload;
      if (loginData) {
        if (loginData?.data.status == true) {
          setItemLocalStorage(
            "accessToken",
            loginData.data.data.auth.accessToken
          );
          setItemLocalStorage(
            "refreshToken",
            loginData.data.data.auth.refreshToken
          );
          setItemLocalStorage("userId", loginData.data.data.id);
          setItemLocalStorage("adminId", loginData.data.data.adminId);
          history.push("/dashboard");
        } else {
          swal({
            title: "error!",
            text: loginData?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Error",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };

  return (
    <div>
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} md={9} lg={6}>
            <div class="adminLogin">
              <img src={Logo} className="img-fluid logo" />
              <span className="adminText">Administration Panel</span>
              <Form className="adminForm" onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="johnsmith1@gmail.com"
                    className="form-input"
                    autoComplete="off"
                    required
                    name="email"
                    pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter Password"
                    className="form-input"
                    name="password"
                    autoComplete="off"
                    required
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <div className="mb-2">
                  
                  <a
                    onClick={() => history.push("/forgot-password")}
                    class="forgotPass"
                  >
                    Forgot Password?
                  </a>
                </div>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>5-Digit Admin ID</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="XXXXX"
                    className="form-input"
                    name="adminId"
                    required
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <a href="#" className="loginLink">
                  <Button
                    variant="primary"
                    type="submit"
                    className="loginButton button"
                  >
                    Login
                  </Button>
                </a>

                <p className="loginError">
                  NOTICE: All user information is confidential and protected by
                  HIPAA. User data should not be copied, changed, or downloaded
                  without proper authorization.
                </p>

                <div className="adminFooter text-center">
                  <img src={Copy} className="copy" />
                  <span>MOV Health 2021</span>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
