import { AuthService } from "../../Services/AuthService";
import {
  LOGIN_SUCCESS,
  GET_SUCCESS,
  LOGOUT_DETAIL_SUCCESS,
  FORGOT_PASSWORD_SEND_CODE,
  UPDATE_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_LINK_VALIDATE,
  GET_LIST_USER_NOTES,
  UPDATE_LIST_USER_NOTES,
  ADD_USER_NOTES,
  GET_USER_DETAILS,
  SET_PRIVATE_POLICY,
  GET_PRIVATE_POLICY,
  GET_TERMS,
  SET_TERMS
} from "./ActionTypes";
import { loadingAction } from "./LoadingAction";

export const loginAction = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.login(data)
      .then((response) => {
        dispatch(loadingAction(false));
        dispatch({ type: LOGOUT_DETAIL_SUCCESS, payload: {} });
        console.log(response);
        return dispatch({ type: LOGIN_SUCCESS, payload: response });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};

export const logoutAction = () => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    localStorage.clear();
    dispatch({ type: LOGIN_SUCCESS, payload: "LOGOUT" });
    return dispatch({ type: LOGOUT_DETAIL_SUCCESS, payload: "LOGOUT" });
  };
};

export const getAllUsersAction = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.getAllUsers(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({ type: GET_SUCCESS, payload: response });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};

export const kitDeactivateAction = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.kitDeactivate(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({ type: GET_SUCCESS, payload: response });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};

export const editPermissionsAction = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.editPermissions(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({ type: GET_SUCCESS, payload: response });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};

export const forgotPasswordAction = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.forgotPassword(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({ type: FORGOT_PASSWORD_SEND_CODE, payload: response });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};
export const updatePasswordAction = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.updatePassword(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({ type: UPDATE_PASSWORD_SUCCESS, payload: response });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};
export const forgotPassworValidatedAction = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.forgotPasswordLinkValidate(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({
          type: FORGOT_PASSWORD_LINK_VALIDATE,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};
export const GetUserAllNotes = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.listNotes(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({
          type: GET_LIST_USER_NOTES,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};

export const updateListNote = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.listNotesUpdate(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({
          type: UPDATE_LIST_USER_NOTES,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};
export const addUserNote = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.add_notes(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({
          type: ADD_USER_NOTES,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};

export const fetchKitDetails = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.getKitDetails(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({
          type: GET_USER_DETAILS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};

export const addPrivatePolicy = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.addPrivatePolicy(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({
          type: SET_PRIVATE_POLICY,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};

export const getPrivatePolicy = () => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.getPrivatePolicy()
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({
          type: GET_PRIVATE_POLICY,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};

export const addTermsOfUse = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.addTermsOfUse(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({
          type: SET_TERMS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};
export const getTermsOfUse = () => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.getTermsOfUse()
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({
          type: GET_TERMS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};
