import { FORGOT_PASSWORD_SEND_CODE, LOGIN_SUCCESS, LOGOUT_DETAIL_SUCCESS, RESET_PASSWORD_SUCCESS, GET_SUCCESS } from "../Actions/ActionTypes"

const iState = {
    loginData: {},
    userData: {},
    logoutDetails: {},
    forgotPassword: {},
    resetPasswordResponse: {}
}

export const authReducer = (state = iState, action) => {
    switch (action.type) {
        
        case LOGIN_SUCCESS:
            return {
                ...state,
                loginData: action.payload
            }
        case GET_SUCCESS:
            return {
                ...state,
                userData: action.payload
            }
        case FORGOT_PASSWORD_SEND_CODE:
            return {
                ...state,
                forgotPassword: action.payload
            }
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPassword: action.payload
            }
        case LOGOUT_DETAIL_SUCCESS:
            return {
                ...state,
                logoutDetails: action.payload
            }
        default:
            return state;
    }

}