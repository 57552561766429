import React from "react";
import { Route, Redirect } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

export const PrivateRoute = ({component: Component, authTokens, ...rest}) => {
    return(
        <Route
            {...rest}
            render = {props => authTokens ? 
            (<>
               <Header />
                <div>
                    <Container fluid>
                        <Row >
                            <Col             
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                className="tabbmain_container text-left">
                                <Component {...props} />
                            </Col>
                        </Row>
                    </Container>
                </div>   
            <Footer />     
            </>) : 
            (<Redirect to="/" />)}
        />
    )
}