import React, { useState } from "react";
import Copy from '../assets/image/copyright.png'
import { Link } from "react-router-dom";

const Footer = () => {

    return (
        <div>
            <div className="adminFooter text-center mov-foot-area area-2">
                <ul className="list-inline">
                    <li className="list-inline-item">
                      <Link to="/terms_and_condition"> Terms of use </Link>
                     </li>
                     <li className="list-inline-item">
                      ||
                     </li>
                    <li className="list-inline-item">
                        <Link to="/private_policy"> Privacy policy </Link>
                    </li>
                </ul>
                <img src={Copy} className="copy" />
                <span>MOV Health 2021</span>
            </div>
        </div>
    )
}
export default Footer;