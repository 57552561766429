import React from 'react'
import { Route, Redirect } from "react-router-dom";
import { DASHBOARD } from './RoutesConstant';

export const OpenRoute = ({component: Component, authTokens, ...rest}) => {
    return (
        <Route
            {...rest}
            render = {props => authTokens ? 
                (<Redirect to={DASHBOARD} />) : (<Component {...props} />) }
        />
    )
}
