import Header from './Components/Header';
import Footer from './Components/Footer';
import React from "react";
import './assets/style/style.css';
import { Routes } from './Routes';
import { Loader } from './Components/loader/Loader';

function App() {
  return (
    <div className="App">
      <div className="page-wrapper">
        <Routes/>
      </div>
      <Loader/> 
    </div>
  );
}

export default App;
