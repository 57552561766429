import React,{ useEffect, useState } from "react";
import {
  Container,
  Col,
  Row,
  Form,
  Button,
  Accordion
} from "react-bootstrap";
import { useDispatch } from "react-redux";

export const LearningToolKit = () => {
  const [addData, setAddData] = useState('')
  const dispatch = useDispatch();

  return (
    <>
      <div className="mt-2">
        <Container >
          <Row>
            <Col lg={12 } >
              <Accordion >
                <Accordion.Item eventKey="0">
                    <Accordion.Header><span>What is Asthma?</span></Accordion.Header>
                    <Accordion.Body>
                    <p>Asthma is a very common chronic condition that affects more than 500 million people globally. It causes breathing problems such as coughing, wheezing, or shortness of breath. This happens because airways in the lungs get inflamed, smaller, and filled with mucus, usually triggered or made worse by irritants in the air known as  ‘triggers’.</p>
                    <p>Normally, when someone breathes in, air goes in through the nose or mouth, down the windpipe (trachea), and into the airways (bronchioles) of the lungs and exits the body in the opposite direction when breathing out. With asthma, air has a harder time passing through either direction as the muscles around the airways tighten, making airways narrower. This can cause ‘Flare-ups’, more commonly known as Asthma attacks. Some flare-ups are serious, while others are mild. They can happen suddenly or build up over time, but can be so severe that it gets in the way of daily activities. </p>
                    <p>Although no one knows exactly why some people develop Asthma, research experts think it might be a combination of environmental factors and genes, as well as lifestyle choices depending on the health and social economic factors of those affected. It is for this exact reason that Asthma is considered one of the most burdensome chronic diseases in the World today.</p>
                    <p>While there is no cure for Asthma, its symptoms can be managed with medicine and the right care plan to manage triggers to allow you to go about doing just about anything you want to do. </p>
                    <p className="source-text">Sources: KidsHealth From Nemours, National Jewish </p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header><span>Asthma Symptoms and Warning Signs</span></Accordion.Header>
                    <Accordion.Body>
                    <p>The symptoms common with Asthma overlap with several other chronic conditions, though there are certain symptoms that can indicate an asthma episode may be occurring:  </p>
                    <ul>
                      <li>Coughing</li>
                      <li>Shortness of breath</li>
                      <li>Tightness in the chest</li>
                      <li>Wheezing</li>
                    </ul>
                    <p>Many times you receive clues, or early ‘warning signs’, that an asthma episode may be developing far before breathing difficulty begins. Some of the most common warning signs include but are not limited to: </p>
                    <ul>
                      <li>Breathing changes</li>
                      <li>Sneezing</li>
                      <li>Moodiness</li>
                      <li>Headache</li>
                      <li>Runny/stuffy nose</li>
                      <li>Cough</li>
                      <li>Chin or throat itchiness</li>
                      <li>Feeling tired</li>
                      <li>Dark circles under eyes</li>
                      <li>Trouble sleeping</li>
                      <li>Poor tolerance for exercise</li>
                      <li>Downward trend in peak flow numbers</li>
                    </ul>
                    <p>If you notice any of these early warning signs, make sure you follow your Asthma Action Plan or talk to your doctor immediately. If left untreated, your symptoms could become severe and extremely dangerous, or even life-threatening. Signs of severe Asthma may include:</p>
                    <ul>
                      <li>Severe coughing, shortness of breath tightness in the chest and/or wheezing</li>
                      <li>Difficulty talking or concentrating</li>
                      <li>Walking causes shortness of breath</li>
                      <li>Breathing may be shallow and faster or slower than usual</li>
                      <li> Hunched shoulders (posturing)</li>
                      <li>Neck area and between or below the ribs moves inward  with breathing (retractions)</li>
                      <li> Gray or bluish tint to skin, beginning around the mouth (cyanosis)</li>
                    </ul>
                    <p className="text-danger">If any of these symptoms occur, seek emergency help immediately.</p>
                    <p className="source-text">Sources: National Jewish, Health News Review</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header><span>What Triggers Asthma?</span></Accordion.Header>
                    <Accordion.Body>
                    <p>Things that bring on symptoms or flare-ups are called ‘triggers.’ Triggers are the most complex part of Asthma, as they are numerous and aren’t always noticeable to the human eye. Triggers can vary from person to person but some of the most common triggers include:</p>
                    <ul>
                      <li>Allergies Like Pollen, Mold, Weeds, Dust And  Pet Dander</li>
                      <li> Irritants And Pollutants In The Home </li>
                      <li>Respiratory Infections Such As Cold Or Flu</li>
                      <li>Weather Conditions</li>
                      <li>Exercise (Symptoms During Or After)</li>
                      <li>Gastroesophageal Acid Reflux (GERD)</li>
                      <li>Obesity</li>
                      <li>Thunderstorms</li>
                      <li>Mental Health</li>
                      <li>Workplace irritants</li>
                      <li>Stress And Anxiety</li>
                      <li>Cockroaches And Pests</li>
                      <li>Secondhand Smoke</li>
                      <li>Fires</li>
                      <li>Environmental Pollutants</li>
                      <li>Automotive Exhaust</li>
                      <li>Gas Or Fuel Burning Appliances Without Proper Ventilation</li>
                      <li>Carpeting Or Upholstered Furniture</li>
                      <li> Vacuuming Without A HEPA-Certified Filter</li>
                      <li> Dirty HVAC Filters in your home</li>
                      <li>Ozone-Producing Air Purifiers</li>
                      <li>Cleaning Products</li>
                    </ul>
                    <p>A very important part of managing your Asthma is avoiding these triggers as much as possible.</p>
                    <p className="source-text">Sources: Sutter Health</p>

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header><span>How Is Asthma Classified?</span></Accordion.Header>
                    <Accordion.Body>
                    <p>Although each case is different, the general rule of them is that your Asthma is ‘Well-Controlled’ when you are able to go about your day without any noticeable issues. There are several tests that can help determine your severity levels without data, such as the Asthma Control Test by QualityMetrics which you may have already taken at home or at your doctor’s office. </p>
                    <p>There are four distinct levels of asthma severity, each with its own set of symptom parameters. The following table gives a general description of each severity level. </p>
                    <p>The majority of Asthma sufferers fall within the 'Uncontrolled' range due to the overwhelming complexity of the disease. However, just because your Asthma feels uncontrolled now doesn't mean it has to stay that way. By becoming active in your own care, you can make a huge difference in how you go about your every day life, even if you feel fine. Managing any disease is important, especially for something as complex as Asthma. </p>
                    <p className="source-text">Sources: Asthma.com, AAFP, Mayo Clinic, ALA</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header><span>How Do You Manage Asthma?</span></Accordion.Header>
                    <Accordion.Body>
                    <p>While there is no cure for Asthma, you can learn to manage it so that you live a normal, healthy life. You can do this by following these four steps:</p>
                    <p>1. Know your Asthma triggers and minimize contact with them.</p>
                    <p>2. Take your Asthma medicines as  prescribed.</p>
                    <p>3. Track your Asthma and recognize early warning signs.</p>
                    <p>4. Know what to do when your Asthma is getting worse.</p>
                    <p>These steps include things like:</p>
                    <p>Learning more about your asthma</p>
                    <p>Identifying and controlling triggers </p>
                    <p>Identifying and managing other chronic or seasonal diseases  </p>
                    <p>Medication therapy</p>
                    <p>Monitoring asthma symptoms</p>
                    <p>An Asthma Action Plan</p>
                    <p>Monitoring your air, inhaler use, lung function and allergen levels</p>
                    <p>With well controlled Asthma, you can often change Asthma from a major disrupting factor to a relatively minor annoyance that still allows you to do things like: </p>
                    <ul>
                      <li>Participate in activities, including physical activity without symptoms</li>
                      <li>Sleep through the night without symptoms</li>
                      <li>Have normal or near normal lung function</li>
                      <li>Limit missed school or work days</li>
                      <li>Have few, if any, emergency room visits and hospitalizations</li>
                      <li>Have few, if any, side effects from the medications taken</li>
                      <li>Feel good about your Asthma care</li>
                    </ul>
                    <p className="source-text">Sources: AAAFA</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header><span>What is an Asthma Action Plan?</span></Accordion.Header>
                    <Accordion.Body>
                    <p>Keeping track of your condition is one thing, but it is even more important to know what you will do based upon the readings you receive. If you know what to watch for and what steps to take, you will be able to make timely and appropriate decisions about managing your Asthma. This is where the Asthma Action Plan – or AAP – comes into play. </p>
                    <p>An Asthma Action Plan is a written, fully-customized plan created by you and your doctor to help you manage asthma episodes. Your action plan is based on changes in symptoms and peak flow numbers. It will give you information about when and how to use long-term control medications, quick-relief medications and help you decide when to call your doctor or when to seek emergency medical care. It is very important to understand that you should seek medical attention (doctor’s office or emergency room) when you are not responding to treatment at home.</p>
                    <p>If you don’t already have an AAP created with your doctor, make sure you talk to your doctor about creating one as soon as possible. </p>
                    <p className="source-text">Sources: AAFA</p>
                    
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header><span>How Can You Avoid Asthma Triggers?</span></Accordion.Header>
                    <Accordion.Body>
                    <p>While certain triggers affect some more than others, it is extremely important to manage exposure or avoid them altogether no matter where your day make take you. Let’s take a look at some ways you can avoid triggers throughout the day:</p>
                    <p className="text-bold">At Home</p>
                    <p><u>SMOKE</u></p>
                    <ul>
                     <li> Do not allow smoking in the home or car. Remaining smoke smell can trigger asthma.</li>
                     <li>Stay away from people who are smoking.</li>
                     <li>If you smoke, smoke outside.</li>
                    </ul>
                    <p><u>DUST</u></p>
                    <ul>
                      <li>Vacuum weekly with a vacuum with a high efficiency filter or a central vacuum. Try to make sure people with asthma are not home during vacuuming.</li>
                      <li>Remove carpet if possible. Wet carpet before removing and then dry floor completely.</li>
                      <li>Damp mop floors weekly</li>
                      <li>Wash bedding and stuffed toys in hot water every 1-2 weeks or freeze stuffed toys that aren't washable for 24 hours.</li>
                      <li>Cover mattresses and pillows in dust-mite proof zippered covers.</li>
                      <li>Reduce clutter and remove stuffed animals, especially around the bed</li>
                      <li>Replace heating system filters regularly.</li>
                    </ul>
                    <p><u>PESTS</u></p>
                    <ul>
                      <li>Do not leave food or garbage out. Store food in airtight containers</li>
                      <li>Try using traps and poison baits, such as boric acid for cockroaches. Instead of sprays/bombs, use baits placed away from children, such as behind refrigerator.</li>
                      <li>Vacuum up cockroach bodies and fill holes in with caulking or copper wool.</li>
                      <li>Fix leaky plumbing, roof, and other sources of water.</li>
                    </ul>
                    <p><u>MOLD</u></p>
                    <ul>
                      <li>Use exhaust fans or open windows for cross ventilation when showering or cooking</li>
                      <li>Clean mold off hard surfaces with detergent in hot water and scrub with stiff brush or cleaning pad, then rinse clean with water. Absorbent materials with mold may need to be replaced</li>
                      <li> Make sure people with asthma are not in the room when cleaning.</li>
                      <li>Fix leaky plumbing or other sources of water or moisture.</li>
                      <li>Always make sure humidity is below 50% in the home</li>
                    </ul>
                    <p><u>ANIMALS</u></p>
                    <ul>
                      <li>Consider not having pets. Avoid pets with fur or feathers.</li>
                      <li>Keep pets out of the bedroom of the person with asthma.</li>
                      <li>Wash your hands and the hands of the person with asthma after petting animals.</li>
                    </ul>
                    <p><u>ODORS/SPRAYS</u></p>
                    <ul>
                      <li>Avoid using strongly scented products, such as home deodorizers and incense, and perfumed laundry products and personal care products</li>
                      <li>Do not use oven/stove for heating.</li>
                      <li>When cleaning, keep person with asthma away and don't use strong smelling cleaning products.</li>
                      <li>Avoid aerosol products.</li>
                      <li>Avoid strong or extra strength cleaning products.</li>
                      <li>Avoid ammonia, bleach, and disinfectants.</li>
                    </ul>
                    <p><u>COLDS/FLU</u></p>
                    <ul>
                      <li>Keep your body healthy with enough exercise and sleep.</li>
                      <li> Avoid close contact with people who have colds.</li>
                      <li>Wash your hands frequently and avoid touching your hands to your face.</li>
                      <li> Get an annual flu shot.</li>
                      <li>Get a flu shot every year, and a pneumonia vaccine if a  healthcare provider advises it.</li>
                      <p>Wear a mask to lessen your risk of COVID-19</p>
                    </ul>
                    <p><u>EXERCISE</u></p>
                    <ul>
                      <li>Warm up before exercising.</li>
                      <li>Plan alternate indoor activities on high pollen or pollution days.</li>
                      <li>If directed by physician, take medication before exercise.</li>
                    </ul>
                    
                    <p className="text-bold">Outdoor Environment</p>
                    <p>Ozone, particle pollution and allergens can all cause symptoms, and even evere asthma attacks. </p>
                    <p><u>WEATHER AND AIR POLLUTION</u></p>
                    <ul>
                      <li> Too hot or too cold weather temperatures can have a negative affect on your Asthma. If cold air is a problem, try breathing through your nose rather than your mouth and covering up with a scarf.</li>
                      <li>Check for High AQI days and nights and avoid strenuous exercise at those times.</li>
                      <li>On very bad AQI or Pollen days, stay indoors with windows closed.</li>
                      <li>When AQI reports unhealthy levels, limit outdoor activities as much as you can. If you must be outdoors, make sure to shower and change clothes to avoid brining allergens into the home </li>
                    </ul>
                    <p><u>POLLEN AND OUTDOOR MOLDS</u></p>
                    <ul>
                      <li>Try to stay indoors when pollen and mold counts are high.</li>
                      <li>Keep windows closed during pollen season.</li>
                      <li>Avoid using fans; use air conditioners.</li>
                    </ul>
                    <p className="text-bold">At Work</p>
                    <p>Asthma is considered work-related when it is caused or made worse by something at the jobsite. Always alert management to mold or other triggers at work and wear a mask to limit your exposure to workplace irritants. If you have any asthma symptoms at work, whatever the cause, notify your doctor right away.</p>
                    <p className="text-bold">At School </p>
                    <p>School personnel have an important role in improving the health of students with Asthma. School-based self-management interventions for asthma control provide education or counseling to help students with Asthma learn to do one or more of the following: recognize and manage symptoms, use medications and inhalers properly, or avoid triggers. Speak with your school nurse to make sure you have a plan for Asthma at school. </p>
                    <p className="source-text">Sources: Ramp Asthma, NIH, KidsHealth from Nemours, EPA, ALA, CDC, CDPH</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                    <Accordion.Header><span>Measuring Your Lung Function</span></Accordion.Header>
                    <Accordion.Body>
                    <p>Asthma is a respiratory disease so keeping track of how well your lungs are functioning is a very important part of managing your health. To do this, most patients use what is called a Peak Air Flow Meter, or PAF. A PAF is a portable, hand-held device that measures your Peak Expiratory Flow Rate and FEV-1, or how well air flows from your lungs when you exhale quickly. In other words, it tells you how open your airways are and can be a good indication of how well your lungs are functioning in comparison to someone of your age range.</p>

                    <p>When used as a regular part of your routine, the PAF meter can be important communication tool to help you and your healthcare professional make decisions about your treatment plan, such as identifying triggers, determining if your medications are working, if they need to be changed and if you need emergency care during a flare-up.</p>

                    <p>Asthma can change very gradually so a PAF can be used as an early warning tool of when your asthma may be getting worse, even before you feel symptoms. A daily, or regular, record of your PAF numbers can provide you with a valuable early warning sign before flare-ups occur. Many times PAF numbers will decrease hours, or even a day or two, before other Asthma symptoms become noticeable. When you monitor your PAF on a daily or regular basis, you can identify this drop and take the needed steps to prevent flare-ups. </p>
                    <p>If your asthma is well controlled and you know the "normal" range of your lung function over a period of time, you may decide to measure your peak flow rate only when you sense that your asthma is getting worse. Those with uncontrolled or more severe Asthma may require several measurements daily. </p>
                    <p>Remember to keep your PAF properly cleaned using mild detergent in hot water to maintain reading accuracy.</p>
                    <p className="source-text">Sources: AAAFA, ALA, National Jewish </p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                    <Accordion.Header><span>Asthma Medication</span></Accordion.Header>
                    <Accordion.Body>
                    <p>Medication is a very important part of any Asthma treatment plan. Depending on the severity of your Asthma, medications can be taken on an as-needed basis or regularly to prevent or decrease breathing difficulty. Most people with asthma benefit from preventive, or long-term, treatment because this reduces the inflammation in the airways and the possibility of chronic obstruction. This gives ongoing protection even if there are no apparent symptoms. </p>

                    <p>For many, a combination of medications is prescribed, though the important thing to remember is that there is no one “best” drug regimen for everyone. The medication program must be individualized to your needs alongside your doctor. Monitoring your asthma and working with your doctor on an ongoing basis is the best way to ensure that the medication program is appropriate for you.</p>
                    <p>
                    Let's take a look at the most common types of Asthma medications:
                    </p>
                     <p>a)  <b>Long-term control medications (Preventatives)</b> – these are used daily to maintain control of your Asthma and prevent symptoms. They are usually in the form of an oral inhalant in dry powder or aerosol form, while some are pill or syrup-based. You must take these medications as prescribed to prevent symptoms, even when the asthma seems better.</p>

                    <p>b) <b>Quick-relief medications (Rescue Inhalers, or MDIs)</b> – these are used to treat sudden flare-ups, hence 'Quick-relief,' or during exercise. They are usually in the form of an oral aerosol-based inhalant, or spray. Quick-relief medications are not a substitute for long-term control medications, and can be fatal if taken more than prescribed so only use these medications as directed by your doctor. If you’re taking these medications more often than prescribed by your doctor, it is likely you are not in control and need to speak to your doctor immediately. Keeping track of your Quick Relief inhaler use is an extremely important routine for managing your Asthma!</p>

                    <p><span className="text-danger">IMPORTANT NOTE: Remember to always keep a rescue inhaler with you wherever you go.</span>. Don’t be embarrassed to have it with you. Many Olympians and world-class athletes carry their rescue inhaler with them at all times because it could save their life if they have an unexpected flare-up. Just because you feel fine now does not mean you can't have an attack at any time. Asthma is very random and flare-ups can occur when you least expect it. Speak with your healthcare professional about making sure you have access to a rescue inhaler at work, school, home, and wherever you travel frequently. </p>
                    <p className="source-text">Sources: National Jewish </p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                    <Accordion.Header><span>How to Use a Relief Inhaler</span></Accordion.Header>
                    <Accordion.Body>
                    <p>Trust us, we get it. Inhalers can be tricky. Depending on your age and experience, using one properly is not always as simple as it may seem. Especially at times when it is needed most. </p>
                    <p>The Metered Dose Inhaler, or MDI, is the most commonly known inhaler today and is generally used for quick relief from flare-ups or before exercise. It is generally referred to as a Rescue or Relief Inhaler. Most asthmatics have this type of inhaler with them at all times, though it still remains the most widely misused inhaler on the market. So, let's break it down into easy steps so you can use it properly: </p>

                   <p><b>Step 1:</b> Remove the cap. </p>
                   <p><b>Step 2:</b> Shake the inhaler a few times to make sure it is primed</p>
                   <p><b>Step 3:</b> Exhale as much air as you can. </p>
                   <p><b>Step 4:</b> Inhale with a big deep breath while simultaneously pressing the  top of the inhaler canister downward to release the spray.</p>
                   <p><b>Step 5:</b> Hold your breathe for 10 seconds, or as long as you can, then exhale normally. </p>
                   <p>Repeat the process again if needed.</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10">
                    <Accordion.Header><span>Disclaimers </span></Accordion.Header>
                    <Accordion.Body>
                    <p>This content is for informational purposes only. It is not intended to replace professional medical advice. Do not use this content as a substitute for professional medical advice. Ask your physician or other health provider if you have any questions about your medical condition.</p>
                    <p className="source-text"><small>Content Sources: Nemours, National Jewish, Health News Review, Asthma.com, American Academy of Family Physicians, Mayo Clinic, American Lung Association, Asthma and Allergy Foundation of America, Sutter Health, RAMP Asthma, National Institute of Health, California Department of Public Health. Illustrations provided by  StoryNet.</small></p>
                    </Accordion.Body>
                </Accordion.Item>
                </Accordion> 
                <p className="text-p-info">
                This content is for informational purposes only. It is not intended to replace professional medical advice. Ask your physician or other health provider if you have any questions about your medical condition.
                </p>
            </Col>
    </Row>
    </Container>
    </div>
                      
    </>
  );
};
