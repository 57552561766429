import axios from "axios";
import { baseURL } from "./BaseApi";

const getHeaders = () => {
  const apiKey = "mv52ea2d-4567-4956-9d19-35a7e75a2c17";
  const platform = "web";
  const accessToken = localStorage.getItem("accessToken");
  return {
    headers: {
      authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
      "x-api-key": apiKey,
      platform: platform,
    },
  };
};

const getLoginHeaders = () => {
  const apiKey = "mv52ea2d-4567-4956-9d19-35a7e75a2c17";
  const platform = "web";
  return {
    headers: {
      Accept: "application/json",
      "x-api-key": apiKey,
      platform: platform,
    },
  };
};

export const AuthService = {
  login: (data) => {
    return axios.post(baseURL + `/api/v1/admin/login`, data, getLoginHeaders());
  },
  getAllUsers: (data) => {
    return axios.post(
      baseURL + `/api/v1/user/get_All_Users`,
      data,
      getHeaders()
    );
  },
  kitDeactivate: (data) => {
    return axios.post(
      baseURL + `/api/v1/user/kit_deactivate`,
      data,
      getHeaders()
    );
  },
  editPermissions: (data) => {
    return axios.post(
      baseURL + `/api/v1/admin/edit_permissions`,
      data,
      getHeaders()
    );
  },
  forgotPassword: (data) => {
    return axios.post(
      baseURL + "/api/v1/auth/userForgotEmailSend",
      data,
      getHeaders()
    );
  },
  forgotPasswordLinkValidate: (data) => {
    return axios.post(
      baseURL + `/api/v1/auth/forgotKeyValidate`,
      data,
      getHeaders()
    );
  },
  // resetPassword: (data) => {
  //   return axios.post(
  //     baseURL + "/api/v1/user/reset_password",
  //     data,
  //     getHeaders()
  //   );
  // },
  updatePassword: (data) => {
    return axios.post(
      baseURL + "/api/v1/user/update_password",
      data,
      getHeaders()
    );
  },
  listNotes: (data) => {
    return axios.post(baseURL + "/api/v1/admin/list_notes", data, getHeaders());
  },
  listNotesUpdate: (data) => {
    return axios.post(baseURL + "/api/v1/admin/edit_note", data, getHeaders());
  },
  add_notes: (data) => {
    return axios.post(baseURL + "/api/v1/admin/add_notes", data, getHeaders());
  },
  getKitDetails: (data) => {
    return axios.post(baseURL + "/api/v1/user/kit_Details", data, getHeaders());
  },

  getPrivatePolicy: () => {
    return axios.get(baseURL + "/api/v1/cms/privacy_policy", getHeaders());
  }, 
  addPrivatePolicy: (data) => {
    return axios.post(baseURL + "/api/v1/cms/update_privacy_policy", data, getHeaders());
  },
  getTermsOfUse: () => {
    return axios.get(baseURL + "/api/v1/cms/terms_of_use", getHeaders());
  },
  addTermsOfUse: (data) => {
    return axios.post(baseURL + "/api/v1/cms/update_terms_of_use", data, getHeaders());
  },
};
