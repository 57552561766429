export const SIGN_IN = "/";
export const DASHBOARD = "/dashboard";
export const PROFILE = "/profile"; 
export const FORGOT_PASSWORD = "/forgot-password";
export const CHANGE_PASSWORD = "/change-password/:id";
export const PRIVATE_POLICY = "/private_policy";
export const TERMS_AND_CONDITION = "/terms_and_condition";
export const TERMS_AND_CONDITION_PAGE = "/terms_and_condition_page";
export const PRIVACY_POLICY_PAGE = "/privacy_policy_page";
export const LEARNING_TOOLKIT = "/learning_toolkit";

