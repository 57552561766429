//========== Loading Action ==========
export const LOADING = "LOADING";

// ========= Auth Action =============
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const FORGOT_PASSWORD_SEND_CODE = "FORGOT_PASSWORD_SEND_CODE";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const LOGOUT_DETAIL_SUCCESS = "LOGOUT_DETAIL_SUCCESS";
export const GET_SUCCESS = "GET_SUCCESS";
export const FORGOT_PASSWORD_LINK_VALIDATE = "FORGOT_PASSWORD_LINK_VALIDATE";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const GET_LIST_USER_NOTES = "GET_LIST_USER_NOTES";
export const UPDATE_LIST_USER_NOTES = "UPDATE_LIST_USER_NOTES";
export const ADD_USER_NOTES = "ADD_USER_NOTES";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const SET_PRIVATE_POLICY = "SET_PRIVATE_POLICY";
export const GET_PRIVATE_POLICY = "GET_PRIVATE_POLICY";
export const SET_TERMS = "SET_TERMS";
export const GET_TERMS = "GET_TERMS";

