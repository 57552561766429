import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Dashboard,
  Login,
  Profile,
  ForgotpassWord,
  ForgotpassWordUpdate,
  PrivatePolicy,
  TermAndCondition,
  TermsAndConditionData,
  PrivacyPolicyData,
  LearningToolKit
} from "../Components";
import { PrivateRoute } from "./PrivateRoute";
import { getItemLocalStorage } from "../Utils/Util";
import {
  DASHBOARD,
  PROFILE,
  SIGN_IN,
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  PRIVATE_POLICY,
  TERMS_AND_CONDITION,
  TERMS_AND_CONDITION_PAGE,
  PRIVACY_POLICY_PAGE,
  LEARNING_TOOLKIT
} from "./RoutesConstant";
import { OpenRoute } from "./OpenRoute";

export const Routes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    getItemLocalStorage("accessToken") ? true : false
  );
  const userLogin = useSelector((state) => {
    return state.authReducer;
  });

  useEffect(() => {
    const existingAuth = getItemLocalStorage("accessToken");
    if (existingAuth) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [userLogin]);

  return (
    <BrowserRouter>
      <Switch>
        <OpenRoute
          exact
          path={SIGN_IN}
          component={Login}
          authTokens={isAuthenticated}
        />
        <OpenRoute
          exact
          path={FORGOT_PASSWORD}
          component={ForgotpassWord}
          authTokens={isAuthenticated}
        />
        <OpenRoute
          exact
          path={CHANGE_PASSWORD}
          component={ForgotpassWordUpdate}
          authTokens={isAuthenticated}
        />
        <PrivateRoute
          exact
          path={PROFILE}
          component={Profile}
          authTokens={isAuthenticated}
        />
        <PrivateRoute
          exact
          path={DASHBOARD}
          component={Dashboard}
          authTokens={isAuthenticated}
        />
         <PrivateRoute
          exact
          path={PRIVATE_POLICY}
          component={PrivatePolicy}
          authTokens={isAuthenticated}
        />
        <PrivateRoute
          exact
          path={TERMS_AND_CONDITION}
          component={TermAndCondition}
          authTokens={isAuthenticated}
        />
        <OpenRoute
          exact
          path={TERMS_AND_CONDITION_PAGE}
          component={TermsAndConditionData}
          // authTokens={isAuthenticated}
        />
        <OpenRoute
          exact
          path={PRIVACY_POLICY_PAGE}
          component={PrivacyPolicyData}
          // authTokens={isAuthenticated}
        />
        <OpenRoute
          exact
          path={LEARNING_TOOLKIT}
          component={LearningToolKit}
          authTokens={isAuthenticated}
        />
      </Switch>
    </BrowserRouter>
  );
};
